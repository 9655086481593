const members_2023 = [
  {
    name: 'Tushar Gupta',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Tushar Gupta.jpeg',
    linkedin: 'https://www.linkedin.com/in/tushar-gupta14/',
    degree: 'Computer Science Engineering',
    graduation_year: 2023,
    workplace: 'Sprinklr',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Shivansh Sethi',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Shivansh Sethi.jpeg',
    linkedin: 'https://www.linkedin.com/in/shivansh-sethi-a53670191/',
    degree: 'Mathematics',
    graduation_year: 2023,
    workplace: '',
    designation: '',
    additional_info: '',
  },

  {
    name: 'Ekansh Jain',
    image: 'images/Alumni_All_Images/Graduating Batch of 2023/Ekansh Jain.jpeg',
    linkedin: 'https://www.linkedin.com/in/ekansh-jain-982452177/',
    degree: 'Architecture and Regional Planning',
    graduation_year: 2023,
    workplace: 'BlinkIt',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Sayantan Kirtaniya',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Sayantan Kirtania.jpeg',
    linkedin: 'https://www.linkedin.com/in/sayantan-kirtaniya/',
    degree: 'Electronics and Electrical Communication Engineering',
    graduation_year: 2023,
    workplace: 'John Deere India Pvt Ltd',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Abhinav Jha',
    image: 'images/Alumni_All_Images/Graduating Batch of 2023/Abhinav Jha.jpeg',
    linkedin: 'https://www.linkedin.com/in/abhinav-jha-431a901a0/',
    degree: 'Agriculture and Food Engineering',
    graduation_year: 2023,
    workplace: 'TCG Digital',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Manasi Bandhaokar',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Manasi Bandhaokar.jpeg',
    linkedin: 'https://www.linkedin.com/in/manasi-bandhaokar-b3715a16b/',
    degree: 'Civil Engineering',
    graduation_year: 2023,
    workplace: 'Citi',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Umang Aditya',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Umang Aditya.jpeg',
    linkedin: 'https://www.linkedin.com/in/umang-aditya/',
    degree: 'Mining Engineering',
    graduation_year: 2023,
    workplace: 'Cogoport',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Tanishq Bansal',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Tanishq Bansal.jpeg',
    linkedin: 'https://www.linkedin.com/in/tanishq-bansal-2104/',
    degree: 'Architecture and Regional Planning',
    graduation_year: 2023,
    workplace: 'BlinkIt',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Sai Chandan',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Sai Chandan Kadarla.jpeg',
    linkedin: 'https://www.linkedin.com/in/saichandankadarla/',
    degree: '',
    graduation_year: 2023,
    workplace: 'Infonetica',
    designation: 'Founding Team Member, Expresso',
    additional_info: 'Student Member',
  },
  {
    name: 'Archit Rungta',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Archit Rungta.jpeg',
    linkedin: 'https://www.linkedin.com/in/archit-rungta-6675a288/',
    degree: 'Mathematics',
    graduation_year: 2023,
    workplace: 'Jane Street',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Ikshu Kumbhar',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Ikshu Manjan Kumbhar.jpeg',
    linkedin: 'https://www.linkedin.com/in/ikshu-manjan-kumbhar-193b291a1/',
    degree: 'Industrial and Systems Engineering',
    graduation_year: 2023,
    workplace: 'Deloitte',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Safeer Subhan',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Safeer Subhan.jpeg',
    linkedin: '',
    degree: '',
    graduation_year: 2023,
    workplace: '',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Soumyadeep Saha',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Soumyadeep Saha.jpeg',
    linkedin: 'https://www.linkedin.com/in/soumyadeep-saha-711a23183/',
    degree: 'Mechanical Engineering',
    graduation_year: 2023,
    workplace: 'John Deer India Pvt Ltd',
    designation: '',
    additional_info: '',
  },

  {
    name: 'Arnab Bala',
    image: 'images/Alumni_All_Images/generic profile avatar.png',
    linkedin: 'https://www.linkedin.com/in/arnab-bala-20424016b/',
    degree: 'Financial Engineering',
    graduation_year: 2023,
    workplace: '',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Onkar Sabnis',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Onkar Sabnis.jpeg',
    linkedin: 'https://www.linkedin.com/in/onkar-sabnis-883094170/',
    degree: 'Chemical Engineering',
    graduation_year: 2023,
    workplace: 'American Express',
    designation: 'NLP Research Analyst, Enterprai',
    additional_info: 'Advisor',
  },
  {
    name: 'Shailesh Nanisetty',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Shailesh Nanisety.jpeg',
    linkedin: 'https://www.linkedin.com/in/shaileshnanisetty007/',
    degree: 'Industrial and Systems Engineering',
    graduation_year: 2023,
    workplace: 'Synapsis Medical Technologies Inc',
    designation: '',
    additional_info: 'MS, Associate Member',
  },
  {
    name: 'Shivansh Junwal',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Shivansh Junwal.jpeg',
    linkedin: 'https://www.linkedin.com/in/shivansh-junwal-8b3b28148/',
    degree: 'Economics',
    graduation_year: 2023,
    workplace: 'Cars24',
    designation: 'Co-Founder, BusMaps',
    additional_info: 'Associate Member',
  },
  {
    name: 'Abhilash Sinha',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Abhilash Sinha.jpeg',
    linkedin: 'https://www.linkedin.com/in/abhilash-sinha-1216a11b3/',
    degree: 'Chemical Engineering',
    graduation_year: 2023,
    workplace: 'BNY Mellon',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Abhranil Chandra',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Abhranil Chandra.jpeg',
    linkedin: 'https://www.linkedin.com/in/abhranil-chandra-462332136/',
    degree: 'Mechanical Engineering',
    graduation_year: 2023,
    workplace: 'University of Waterloo',
    designation: '',
    additional_info: 'Masters',
  },
  {
    name: 'Arun Kumar Nayak',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Arun Kumar Nayak.jpeg',
    linkedin: 'https://www.linkedin.com/in/arun-nayak-7104a51aa/',
    degree: 'Metallurgical Engineering',
    graduation_year: 2023,
    workplace: 'UIDAI',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Ayush Surana',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Ayush Surana.jpeg',
    linkedin: 'https://www.linkedin.com/in/ayushsurana1/',
    degree: 'Chemical Engineering',
    graduation_year: 2023,
    workplace: 'Google',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Chinmay Jain',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Chinmay Jain.jpeg',
    linkedin: 'https://www.linkedin.com/in/chinmay-jain-a833aa193/',
    degree: 'Chemical Engineering',
    graduation_year: 2023,
    workplace: 'Walmart',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Divyangna Sharma',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Divyangna Sharma.jpeg',
    linkedin: 'https://www.linkedin.com/in/divyangna-sharma-5354291b5/',
    degree: 'Chemistry',
    graduation_year: 2023,
    workplace: 'JP Morgan Chase & Co.',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Rahul Mandal',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Rahul Mandal.jpeg',
    linkedin: 'https://www.linkedin.com/in/tesla132/',
    degree: 'Mechanical Engineering',
    graduation_year: 2023,
    workplace: 'UNIROX BIKES',
    designation: '',
    additional_info: 'MBA',
  },
  {
    name: 'Ratnesh Bhosale',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Ratnesh Bhosale.jpeg',
    linkedin: 'https://www.linkedin.com/in/ratneshbhosale999/',
    degree: 'Mechanical Engineering',
    graduation_year: 2023,
    workplace: 'DTDC',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Roshni Kar',
    image: 'images/Alumni_All_Images/Graduating Batch of 2023/Roshni Kar.jpeg',
    linkedin: 'https://www.linkedin.com/in/roshni-kar/',
    degree: 'Chemical Engineering',
    graduation_year: 2023,
    workplace: 'Pine Labs',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Shalini Bansal',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Shalini Bansal.jpeg',
    linkedin: 'https://www.linkedin.com/in/shalini-bansal-9314431b4/',
    degree: 'Chemical Engineering',
    graduation_year: 2023,
    workplace: 'American Express',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Murali Manohar',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Murali Manohar.jpeg',
    linkedin: 'https://www.linkedin.com/in/murali-manohar-bb848a1a7/',
    degree: 'Mechanical Engineering',
    graduation_year: 2023,
    workplace: 'Axis Bank',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Ronak Agarwal',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Ronak Agarwal.jpeg',
    linkedin: 'https://www.linkedin.com/in/ronakagarwal3434/',
    degree: 'Chemical Engineering',
    graduation_year: 2023,
    workplace: 'Vani',
    designation: '',
    additional_info: '',
  },
  {
    name: 'Yash Agrawal',
    image:
      'images/Alumni_All_Images/Graduating Batch of 2023/Yash Agrawal.jpeg',
    linkedin: 'https://www.linkedin.com/in/yash-agrawal-6b4253194/',
    degree: 'Electronics and Electrical Communication Engineering',
    graduation_year: 2023,
    workplace: 'Bidgely',
    designation: '',
    additional_info: '',
  },
];

export default members_2023;
